/**
 *	Styles
 */

@import "settings/index";
@import "tools/index";
@import "generic/index";
@import "elements/index";
@import "objects/index";
@import "components/index";
@import "vendors/index";
@import "utilities/index";
@import "node_modules/bootstrap/scss/utilities/api";
@import "custom/index";

.editable-input {
  border: none;
  outline: none;
  border-radius: 5px;

  &:focus {
    border: 1px solid rgb(133, 133, 133);
  }
}

.dropdown-menu {
  max-height: 300px;
  overflow-y: auto;
}

.rnc__notification-timer {
  transform: rotate(180deg);
}

.option-card {
  background: #d8f6f2;
  border-radius: 14px;
  padding: 13px;
  margin-top: 12px;
}

.label-option .form-check .form-check-label {
  margin-left: 33px;
  margin-bottom: 10px;
}

.img-background {
  background: #f8f9fa;
}

.elipsis {
  max-width: 300px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.table-description{
  max-width: 150px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.msg {
  position: absolute;
  color: red;
  top: 175px;
  z-index: 2;
  width: 200px;
  text-align: center;
  left: 50px;
}

@media (max-width:768px) {
  // .msg {
  //   left: 0;
  // }
}